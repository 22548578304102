import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _20f203d3 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _01483e2b = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _b798b068 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _318bcfa2 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _596aba60 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _545d6a40 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _fe4df562 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _60c7b86f = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _2e0f1fe8 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _35e9ea0e = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _28c7f028 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _61bbcd73 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _008d6530 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _6400049c = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _078b81b4 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _73a76e8f = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _75aadf59 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _00355362 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _4a155599 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _4780fc6a = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _20f203d3,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _01483e2b,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _b798b068,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _318bcfa2,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _596aba60,
    name: "receipt"
  }, {
    path: "/search",
    component: _545d6a40,
    name: "search"
  }, {
    path: "/search-booking",
    component: _fe4df562,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _60c7b86f,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _2e0f1fe8,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _35e9ea0e,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _28c7f028,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _61bbcd73,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _008d6530,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _6400049c,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _078b81b4,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _73a76e8f,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _75aadf59,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _00355362,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _4a155599,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _4780fc6a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
